



























































































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { AdditionalArchiveBlock, BlockTypeEnum } from "@/generated/graphql";

import ImageInput from "@widgets/commons/Inputs/ImageInput.vue";
import FileInput from "@widgets/commons/Inputs/FileInput.vue";
import Card from "@components/page/Card.vue";
import Download from "@components/Download.vue";
import AdditionalArchiveBlockIcon from "@widgets/events/AdditionalArchiveBlocks/Icon.vue";
import AdditionalArchiveBlockTextPreview from "@widgets/events/AdditionalArchiveBlocks/BlockTextPreview.vue";
import AdditionalArchiveBlockVideoPreview from "@widgets/events/AdditionalArchiveBlocks/BlockVideoPreview.vue";
import AdditionalArchiveBlockImagePreview from "@widgets/events/AdditionalArchiveBlocks/BlockImagePreview.vue";

import AdditionalArchiveBlockText from "@widgets/events/AdditionalArchiveBlocks/BlockText.vue";
import AdditionalArchiveBlockVideo from "@widgets/events/AdditionalArchiveBlocks/BlockVideo.vue";
import AdditionalArchiveBlockImage from "@widgets/events/AdditionalArchiveBlocks/BlockImage.vue";
import { additionalBlocksTypes } from "@/core/static/dict";

@Component({
  name: "AdditionalArchiveBlocks",
  components: {
    Card,
    Download,
    ImageInput,
    FileInput,

    AdditionalArchiveBlockIcon,
    AdditionalArchiveBlockTextPreview,
    AdditionalArchiveBlockVideoPreview,
    AdditionalArchiveBlockImagePreview,

    AdditionalArchiveBlockText,
    AdditionalArchiveBlockVideo,
    AdditionalArchiveBlockImage,
  },
})
export default class AdditionalArchiveBlocks extends Vue {
  @VModel({ type: Array, default: () => [] }) blocks!: Array<
    Partial<
      AdditionalArchiveBlock & {
        files: File[] | AdditionalArchiveBlock["files"];
        images: File[] | AdditionalArchiveBlock["files"];
      }
    >
  >;
  @Prop({ type: Boolean, default: false }) isArchived!: string;

  private readonly BlockTypeEnum = BlockTypeEnum;
  private readonly AdditionalArchiveBlocksTypes = additionalBlocksTypes;

  protected expandedBlock: number | null = null;

  protected addBlock(): void {
    this.blocks.push({ type: BlockTypeEnum.Text });
    this.expandedBlock = this.blocks.length - 1;
  }

  protected deleteBlock(index: number, id: string): void {
    this.blocks.splice(index, 1);
    if (id) this.$emit("delete", id);
  }
}
