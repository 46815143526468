



































import { Vue, Component, Prop, VModel, Watch } from "vue-property-decorator";
import { DateTime } from "luxon";

const DATE_FORMAT = "yyyy-MM-dd";
const TIME_FORMAT = "HH:mm";
const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

@Component({
  name: "DateInput",
})
export default class DateInput extends Vue {
  @VModel({ type: String, default: "" }) model!: string;

  @Prop({ type: String, default: "" }) label!: string;

  @Prop({ type: Boolean, default: true }) showTime!: boolean;

  @Prop({ type: Boolean, default: false }) checkDateFrom!: boolean;

  @Prop({ type: String, default: "" }) dateFrom!: string;

  @Prop({ type: Boolean, default: true })
  public readonly required!: boolean;

  protected visibleDate = false;
  protected visibleTime = false;

  public get datetime(): DateTime {
    return DateTime.fromFormat(
      `${this.date} ${this.time}`.trim(),
      DATETIME_FORMAT
    );
  }

  public allowedDates: (value: string, format: string) => boolean = () => true;

  public isValidDate(date: string): boolean {
    if (date && date.replace(/\D/g, "").length === 8) {
      return DateTime.fromFormat(date, "dd-MM-yyyy").isValid;
    }
    return false;
  }

  public dateRules: Array<(value: string) => boolean | string> = [
    (value: string): boolean | string => {
      if (this.required && !value) {
        return "Обязательно для заполнения";
      } else {
        return (
          (this.isValidDate(value) &&
            +DateTime.fromFormat(value, "dd-MM-yyyy").toFormat("yyyy") >=
              1900) ||
          (!this.required && !value) ||
          "Неверный формат даты"
        );
      }
    },
  ];

  @Watch("dateFrom")
  public setAllowedDates(): void {
    if (this.checkDateFrom && this.dateFrom) {
      this.allowedDates = (value: string) => {
        return (
          DateTime.fromISO(value) >
          DateTime.fromISO(this.dateFrom).minus({ day: 1 })
        );
      };
      this.dateRules.push((value: string) => {
        if (this.isValidDate(value)) {
          return (
            DateTime.fromFormat(value, `dd-MM-yyyy`) >
            DateTime.fromISO(this.dateFrom).minus({ day: 1 })
          );
        }
        return true;
      });
    }
  }

  private get dateFormatted() {
    return this.model ? this.datetime.toFormat(`dd-MM-yyyy`) : "";
  }

  private get timeFormatted() {
    return this.model ? this.datetime.toFormat(`hh:mm`) : "";
  }

  private get luxonDate(): DateTime {
    return DateTime.fromISO(this.model).setLocale("ru");
  }

  private get time() {
    return this.model ? this.luxonDate.toFormat(TIME_FORMAT) : "";
  }
  private set time(value: string) {
    this.model = DateTime.fromFormat(
      `${this.date} ${value || "00:00"}`,
      this.date ? DATETIME_FORMAT : TIME_FORMAT
    ).toISO({
      suppressMilliseconds: true,
    });
  }

  private get date(): string {
    return this.model ? this.luxonDate.toFormat(DATE_FORMAT) : "";
  }

  private set date(value: string) {
    this.model = DateTime.fromFormat(
      `${value} ${this.time}`.trim(),
      this.time ? DATETIME_FORMAT : DATE_FORMAT
    ).toISO({
      suppressMilliseconds: true,
    });
  }
}
