










































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AdditionalBlockVideoPreview extends Vue {
  @Prop({ type: String, default: null }) name!: string | null;
  @Prop({ type: String, default: null }) description!: string | null;
  @Prop({ type: String, default: null }) youtubeLink!: string | null;
}
