var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApolloQuery',{attrs:{"query":_vm.personStatuses},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var query = ref.query;
return [_c('v-autocomplete',_vm._b({attrs:{"items":data ? data.personStatuses : [],"item-text":"name","item-value":"id","search-input":_vm.searchStatusValue,"cache-items":"","label":"Статус","placeholder":"Начните вводить"},on:{"update:searchInput":function($event){_vm.searchStatusValue=$event},"update:search-input":function($event){_vm.searchStatusValue=$event},"change":function($event){_vm.searchStatusValue = ''}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-dialog',{attrs:{"persistent":"","max-width":"400px","width":"100%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex justify-center align-center pa-2"},[_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v("создать")])],1)]}}],null,true),model:{value:(_vm.createStatusVisible),callback:function ($$v) {_vm.createStatusVisible=$$v},expression:"createStatusVisible"}},[_c('ApolloMutation',{attrs:{"mutation":_vm.createPersonStatus,"variables":{ name: _vm.newStatusName }},on:{"done":function($event){query.refetch();
              _vm.createStatusVisible = false;
              _vm.searchStatusValue = _vm.newStatusName;
              _vm.newStatusName = '';}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var mutate = ref.mutate;
              var loading = ref.loading;
              var error = ref.error;
return [_c('v-form',{model:{value:(_vm.newStatusValid),callback:function ($$v) {_vm.newStatusValid=$$v},expression:"newStatusValid"}},[_c('v-card',[_c('v-card-title',[_vm._v("Создание статуса")]),_c('v-alert',{attrs:{"value":error,"color":"red","text":""}},[_vm._v(" Ошибка, возможно, статус уже имеется "),_c('details',{staticClass:"text-body-2"},[_vm._v(_vm._s(error))])]),_c('v-card-text',[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"label":"Название статуса","placeholder":"Название Статуса","loading":loading,"disabled":loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return mutate.apply(null, arguments)}},model:{value:(_vm.newStatusName),callback:function ($$v) {_vm.newStatusName=$$v},expression:"newStatusName"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","small":"","color":"green","loading":loading,"disabled":!_vm.newStatusValid},on:{"click":mutate}},[_vm._v("создать")]),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","text":"","disabled":loading},on:{"click":function($event){_vm.createStatusVisible = false}}},[_vm._v("отменить")])],1)],1)],1)]}}],null,true)})],1),_c('v-divider',{staticClass:"mb-4"})]},proxy:true}],null,true),model:{value:(_vm.statuses),callback:function ($$v) {_vm.statuses=$$v},expression:"statuses"}},'v-autocomplete',_vm.$attrs,false))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }