












































































































































































import { Component, Vue, VModel, Prop } from "vue-property-decorator";
import Table from "@/components/parts/tables/Table.vue";
import {
  CreatePartnerMutation,
  CreatePartnerMutationVariables,
  CreatePartnerStatusMutation,
  CreatePartnerStatusMutationVariables,
  DeletePartnerStatusMutation,
  DeletePartnerStatusMutationVariables,
  Partner,
  PartnerStatus,
} from "@/generated/graphql";
import {
  CreatePartner,
  CreatePartnerStatus,
  DeletePartnerStatus,
  PartnerStatuses,
} from "@/graphql/queries/Partners.graphql";
import { DataTableHeader } from "vuetify";
import ImageInput from "@/components/widgets/commons/Inputs/ImageInput.vue";
import PartnerSearchCard from "@components/PartnerSearchCard.vue";

@Component({
  components: {
    PartnerSearchCard,
    Table,
    ImageInput,
  },
  apollo: {
    partnerStatuses: {
      query: PartnerStatuses,
    },
  },
})
export default class PartnersList extends Vue {
  @Prop({ type: Boolean, default: false }) showSelect!: boolean;
  @VModel({ type: Array, default: () => [] }) partners!: Partner[];

  public created(): void {
    if (!this.partners.length) this.partners = [];
  }

  protected search: string = "";

  protected partnerStatuses: PartnerStatus[] = [];

  protected statusDeleteTipVisible: boolean = false;
  protected partnerPickerVisible: boolean = false;
  protected selected: Partner[] = [];

  protected statusFormProgress: boolean = false;
  protected statusFormError: boolean = false;
  protected statusFormDone: boolean = false;
  protected statusFormVisible: boolean = false;
  protected statusFormValue: string = "";

  protected async addStatus(): Promise<void> {
    try {
      this.statusFormProgress = true;
      await this.$apollo.mutate<
        CreatePartnerStatusMutation,
        CreatePartnerStatusMutationVariables
      >({
        mutation: CreatePartnerStatus,
        variables: { status: this.statusFormValue },
      });
      await this.$apollo.queries.partnerStatuses.refetch();
      this.statusFormDone = true;
    } catch (e) {
      this.statusFormError = true;
      console.error(e);
    } finally {
      this.statusFormProgress = false;
      this.statusFormVisible = false;
      this.statusFormValue = "";
    }
  }

  protected headers: DataTableHeader[] = [
    { text: "ID", value: "id", width: 50, sortable: false },
    { text: "Логотип", value: "logotype", width: 300, sortable: false },
    { text: "Статус", value: "status", width: 400, sortable: false },
    { text: "Подпись", value: "name", sortable: false },
    { text: "Действия", value: "_actions", sortable: false, width: "50" },
  ];

  protected addSelectedPartners(): void {
    this.partners = [...new Set([...this.partners, ...this.selected])];
    this.partnerPickerVisible = false;
  }

  protected statusDeleteProgress: string | null = null;
  protected statusDeleteError: boolean = false;
  protected statusDeleteDone: boolean = false;
  protected async deleteStatus(id: string): Promise<void> {
    try {
      this.statusDeleteProgress = id;
      await this.$apollo.mutate<
        DeletePartnerStatusMutation,
        DeletePartnerStatusMutationVariables
      >({
        mutation: DeletePartnerStatus,
        variables: { id },
      });
      await this.$apollo.queries.partnerStatuses.refetch();
      this.statusDeleteDone = true;
    } catch (e) {
      this.statusDeleteError = true;
      console.error(e);
    } finally {
      this.statusDeleteProgress = null;
      this.statusFormVisible = false;
    }
  }

  /**
   * Этот метод с сайд эффектом добавляет статус партнера
   * @param partner
   * @param status
   * @protected
   */
  protected addPartner(partner: Partner, status: string): void {
    if (!partner.pivot) {
      partner.pivot = {
        partner_status_id: status,
      };
    } else partner.pivot.partner_status_id = status;
  }

  protected partnerFormVisible: boolean = false;
  protected partnerFormProgress: boolean = false;
  protected partnerFormError: boolean = false;
  protected partnerFormDone: boolean = false;
  protected partnerFormInput = {
    name: "",
    url: "",
    logotype: null,
  };

  protected async createPartner(): Promise<void> {
    try {
      this.partnerFormProgress = true;
      const result = await this.$apollo.mutate<
        CreatePartnerMutation,
        CreatePartnerMutationVariables
      >({
        mutation: CreatePartner,
        variables: { input: this.partnerFormInput },
      });
      this.partnerFormVisible = false;
      this.partnerFormDone = true;

      const data = result.data?.createPartner;
      if (data) {
        // @ts-ignore
        this.partners.push({ ...data });

        this.partnerFormInput.url = "";
        this.partnerFormInput.name = "";
        this.partnerFormInput.logotype = null;
      }
    } catch (e) {
      console.error(e);
      this.partnerFormError = true;
    } finally {
      this.partnerFormProgress = false;
    }
  }

  protected removePartner(partner: Partner): void {
    this.partners.splice(this.partners.indexOf(partner), 1);
  }

  onAddPartner(partner: Partner) {
    const isPartnerExist = this.partners.some(
      (listPartner) => listPartner.id === partner.id
    );
    if (!isPartnerExist) {
      this.partners.push(partner);
    }
    this.partnerFormVisible = false;
  }
}
