





















import { Component, Prop, Vue } from "vue-property-decorator";
import { BlockTypeEnum } from "@/generated/graphql";

@Component
export default class AdditionalArchiveBlockIcon extends Vue {
  @Prop({ type: String, required: true }) type!: BlockTypeEnum;

  protected readonly BlockTypeEnum = BlockTypeEnum;
}
