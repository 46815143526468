



















































import { Component, PropSync, Vue } from "vue-property-decorator";

@Component
export default class AdditionalBlockVideo extends Vue {
  @PropSync("name", { type: String, default: null }) nameSync!: string | null;
  @PropSync("description", { type: String, default: null }) descriptionSync!:
    | string
    | null;
  @PropSync("youtubeLink", { type: [Object, String, File], default: null })
  youtubeLinkSync!: string | null;

  private youtubePattern =
    /^http(s)?:\/\/(www\.)?(youtube.com|youtu.be)\/(watch\?v=)?.+$/;

  private youtubeLinkValidator = {
    getMessage() {
      return "inco";
    },
    validate: (value: string): boolean => {
      return this.youtubePattern.test(value);
    },
  };

  protected get youtubeID(): string | null {
    if (!this.isValidLink) return null;
    const videoID = this.youtubeLinkSync?.match(
      /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
    );
    return videoID?.[1] ?? null;
  }

  protected get embedUrl(): string | null {
    return this.isValidLink
      ? `https://www.youtube-nocookie.com/embed/${this.youtubeID}`
      : null;
  }

  protected get isValidLink(): boolean {
    if (!this.youtubeLinkSync) return false;
    return this.youtubePattern.test(this.youtubeLinkSync);
  }
}
