




























































import { Component, PropSync, Vue } from "vue-property-decorator";
import { required } from "@/core/validation";

type fileType = File | null | string;

@Component
export default class AdditionalBlockText extends Vue {
  @PropSync("name", { type: String, default: null }) nameSync!: string | null;
  @PropSync("text", { type: String, default: null }) textSync!: string | null;
  @PropSync("file", { type: [Object, File, String], default: null })
  filesSync!: fileType;
  error: boolean = false;

  protected readonly rules = [required];

  protected get isFile(): boolean {
    return this.filesSync instanceof File;
  }
  get localFile(): fileType {
    return typeof this.filesSync === "string" ? null : this.filesSync;
  }
  set localFile(file: fileType) {
    this.filesSync = file;
  }
  protected onFileUpload(file: File): void {
    const types = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      "application/msword",
      "text/plain",
    ];
    if (!types.includes(file.type)) {
      this.error = true;
      this.filesSync = null;
    }
  }
  protected get filename(): string {
    let name =
      this.filesSync && this.filesSync instanceof File
        ? this.filesSync?.name
        : typeof this.filesSync == "string"
        ? this.filesSync
        : "" || "";
    return name.indexOf("/")
      ? name.split("/").pop()?.substr(0, 100) || ""
      : name
      ? name
      : "";
  }
}
