















































import { Component, Prop, Vue } from "vue-property-decorator";
import DownloadButton from "@components/Download.vue";

@Component({
  components: {
    DownloadButton,
  },
})
export default class AdditionalArchiveBlockImagePreview extends Vue {
  @Prop({ type: String, default: null }) name!: string | null;
  @Prop({ type: String, default: null }) text!: string | null;
  @Prop({ type: [String, Object, Array], default: null }) image!:
    | File
    | File[]
    | string
    | null;

  protected get imageIsFile(): boolean {
    return this.image instanceof File;
  }

  protected get imageIsLink(): boolean {
    return typeof this.image === "string";
  }
}
