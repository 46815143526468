var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[(!_vm.disableHideCheckbox)?_c('div',{staticClass:"px-4 py-2"},[_c('v-checkbox',{attrs:{"label":"Скрыть","persistent-hint":"","hint":"Блок будет скрыт в WEB приложении"},model:{value:(_vm.hideBlockSync),callback:function ($$v) {_vm.hideBlockSync=$$v},expression:"hideBlockSync"}})],1):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('v-dialog',{attrs:{"max-width":"1024","width":"100%","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"primary","text":""},on:{"click":function($event){_vm.selected = [].concat( _vm.items )}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-account-plus")]),_vm._v(" Добавить ")],1)]}}]),model:{value:(_vm.usersVisible),callback:function ($$v) {_vm.usersVisible=$$v},expression:"usersVisible"}},[_c('v-card',{staticClass:"rounded-md"},[_c('v-card-title',[_vm._v("Добавить пользователя")]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('UsersList',{attrs:{"show-select":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"green","dark":"","depressed":""},on:{"click":function($event){_vm.items = [].concat( _vm.selected );
                  _vm.usersVisible = false;}}},[_vm._v("готово")])],1)],1)],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('PersonStatusAutocomplete',{attrs:{"value":_vm.getPersonStatus(item),"error-messages":errors},on:{"input":function($event){return _vm.setPersonStatus(item, $event)}}})]}}],null,true)})]}},{key:"item.avatar",fn:function(ref){
                  var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"40"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('v-icon',[_vm._v("mdi-account")])],1)]}},{key:"item.fullname",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s([item.family_name, item.first_name, item.second_name].join(" "))+" ")]}},{key:"item.comment",fn:function(ref){
                  var item = ref.item;
return [(item.comment)?_c('span',[_vm._v(_vm._s(item.comment.substr(0, 100))+" "+_vm._s(item.comment.length > 100 ? "..." : ""))]):_c('span',{staticClass:"v-data-table__empty-wrapper"},[_vm._v("нет регалий")])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){_vm.revokeUser = item.id}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-minus")])],1),_c('v-dialog',{attrs:{"value":_vm.revokeUser === item.id,"max-width":"400"}},[_c('v-card',{staticClass:"rounded-md"},[_c('v-card-title',[_vm._v("Исключить пользователя?")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"grey lighten-3 rounded px-4 ma-2"},[_c('v-list-item-avatar',[_c('v-avatar',[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('v-icon',[_vm._v("mdi-account")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s([ item.family_name, item.first_name, item.second_name ].join(" "))+" ")]),(item.comment)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.comment)+" ")]):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":"","small":""},on:{"click":function($event){return _vm.spliceUser(item)}}},[_vm._v(" исключить ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","text":""},on:{"click":function($event){_vm.revokeUser = null}}},[_vm._v(" отмена")])],1)],1)],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.openUserModal}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }