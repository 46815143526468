
















































import { Component, PropSync, Vue } from "vue-property-decorator";
type FileDefinition = string | File | { url: string } | null;

@Component
export default class AdditionalArchiveBlockText extends Vue {
  @PropSync("name", { type: String, default: null }) nameSync!: string | null;
  @PropSync("text", { type: String, default: null }) textSync!: string | null;
  @PropSync("files", { type: [Array, String, File], default: () => [] })
  filesSync!: FileDefinition[];
  error: boolean = false;

  protected onFileUpload(files: File[]): void {
    const types = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      "application/msword",
      "text/plain",
    ];

    const isFileSupport = (f: File) => types.includes(f.type);
    const validFiles = files.filter(isFileSupport);
    const invalidFiles = files.filter((f) => !isFileSupport(f));

    if (invalidFiles.length > 0) {
      this.error = true;
      this.filesSync = validFiles;
    }
  }

  protected get isFile(): boolean {
    return this.filesSync instanceof File;
  }

  protected get filenames(): string[] {
    return this.filesSync.map((file) => {
      let filename: string = "";
      if (file instanceof File) filename = file.name;
      else if (typeof file === "object" && file?.url) filename = file.url;
      else if (typeof file === "string") filename = file;
      return filename.split("/").pop()?.substr(0, 100) || "";
    });
  }
}
