













































import { Component, Prop, Vue } from "vue-property-decorator";
import DownloadButton from "@components/Download.vue";

@Component({
  components: {
    DownloadButton,
  },
})
export default class AdditionalArchiveBlockTextPreview extends Vue {
  @Prop({ type: String, default: null }) name!: string | null;
  @Prop({ type: String, default: null }) text!: string | null;
  @Prop({ type: Array, default: () => [] }) files!: Array<File | string>;

  protected get newFiles(): Array<File | string> {
    return this.files.filter((file) => file instanceof File);
  }
}
