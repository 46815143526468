






















































































































import { Component, VModel, Vue } from "vue-property-decorator";
import { PersonStatus } from "@/generated/graphql";
import {
  createPersonStatus,
  personStatuses,
  updatePersonStatus,
  deletePersonStatus,
} from "@/graphql/queries/Events.graphql";
import * as rules from "@/core/validation";

@Component
export default class PersonStatusAutocomplete extends Vue {
  @VModel({ type: [Object, String, Array], default: [] }) statuses!:
    | PersonStatus
    | PersonStatus[]
    | PersonStatus["id"];

  protected readonly personStatuses = personStatuses;
  protected readonly createPersonStatus = createPersonStatus;
  protected readonly updatePersonStatus = updatePersonStatus;
  protected readonly deletePersonStatus = deletePersonStatus;
  protected createStatusVisible = false;
  protected newStatusValid = false;
  protected searchStatusValue = "";
  protected newStatusName = "";
  protected readonly rules = rules;
}
