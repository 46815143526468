




















































import { Component, Vue, Prop } from "vue-property-decorator";
import { User, Statistic, KeyValue, Statuses } from "@/generated/graphql";
import { StatisticsStatus } from "@/core/static/dict";

@Component
export default class Statistics extends Vue {
  @Prop({ required: true, type: Array })
  participants!: User[];

  @Prop({ required: true, type: Object })
  statistic!: Statistic;

  readonly StatisticsStatus = StatisticsStatus;

  protected computeWidth(val: [KeyValue | Statuses], count: number): string {
    const total = val.reduce((acc: number, q) => acc + q.count, 0);
    return "width:" + (count / total) * 100 + "%";
  }
}
