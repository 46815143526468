



























































import { Component, VModel, Vue } from "vue-property-decorator";
import PageCard from "@components/page/Card.vue";
import { extend as VeeExtend } from "vee-validate";
import { filePdf } from "@/core/validation";

/* TODO: После замены ValidationProvider на нативную валидацию,
         перенести в класс компонента и использовать в rules компонента v-file-input
 */
VeeExtend("pdf", filePdf);

@Component({
  components: { PageCard },
})
export default class EventProgram extends Vue {
  @VModel({ type: [String, Object, File] }) file!: File | string;

  public $refs!: {
    input: Vue & { $refs: { input: HTMLInputElement } };
  };

  public get filename(): string {
    return this.file instanceof File ? this.file.name : this.file;
  }

  public get fileUploaded(): boolean {
    return typeof this.file === "string" && !!this.file;
  }

  public get fileSelected(): boolean {
    return this.file instanceof File;
  }

  /**
   * Триггерит v-file-input и открывает окно выбора файлов
   * @protected
   */
  protected openFiles(): void {
    this.$refs.input.$refs.input.click();
  }
}
