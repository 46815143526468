































import { Component, PropSync, Vue } from "vue-property-decorator";
import ImageInput from "@widgets/commons/Inputs/ImageInput.vue";

@Component({ components: { ImageInput } })
export default class AdditionalArchiveBlockImage extends Vue {
  @PropSync("name", { type: String, default: null }) nameSync!: string | null;
  @PropSync("text", { type: String, default: null }) textSync!: string | null;
  @PropSync("files", { type: [Object, String, File], default: null })
  filesSync!: File | string | null;
  @PropSync("images", { type: [Object, String, File, Array], default: null })
  imagesSync!: File[] | string[] | null;

  protected get isFile(): boolean {
    return this.filesSync instanceof File;
  }

  protected get filename(): string {
    return "";
  }
}
