

































































































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import {
  AdditionalArchiveBlock,
  AdditionalBlock,
  BlockTypeEnum,
} from "@/generated/graphql";
import { additionalBlocksTypes } from "@/core/static/dict";

import ImageInput from "@widgets/commons/Inputs/ImageInput.vue";
import FileInput from "@widgets/commons/Inputs/FileInput.vue";
import Card from "@components/page/Card.vue";
import Download from "@components/Download.vue";
import AdditionalBlockIcon from "@widgets/events/AdditionalBlocks/Icon.vue";
import AdditionalBlockTextPreview from "@widgets/events/AdditionalBlocks/BlockTextPreview.vue";
import AdditionalBlockVideoPreview from "@widgets/events/AdditionalBlocks/BlockVideoPreview.vue";
import AdditionalBlockImagePreview from "@widgets/events/AdditionalBlocks/BlockImagePreview.vue";

import AdditionalBlockText from "@widgets/events/AdditionalBlocks/BlockText.vue";
import AdditionalBlockVideo from "@widgets/events/AdditionalBlocks/BlockVideo.vue";
import AdditionalBlockImage from "@widgets/events/AdditionalBlocks/BlockImage.vue";

@Component({
  name: "AdditionalBlocks",
  components: {
    Card,
    Download,
    ImageInput,
    FileInput,

    AdditionalBlockIcon,
    AdditionalBlockTextPreview,
    AdditionalBlockVideoPreview,
    AdditionalBlockImagePreview,

    AdditionalBlockText,
    AdditionalBlockVideo,
    AdditionalBlockImage,
  },
})
export default class AdditionalBlocks extends Vue {
  @VModel({ type: Array, default: () => [] }) blocks!: Array<
    Partial<AdditionalBlock & AdditionalArchiveBlock & { file: File | string }>
  >;
  @Prop({ type: Boolean, default: false }) isArchived!: string;

  private readonly BlockTypeEnum = BlockTypeEnum;
  private readonly additionalBlocksTypes = additionalBlocksTypes;

  protected expandedBlock: number | null = null;

  protected addBlock(): void {
    this.blocks.push({ type: BlockTypeEnum.Text });
    this.expandedBlock = this.blocks.length - 1;
  }

  protected deleteBlock(index: number, id: string): void {
    this.blocks.splice(index, 1);
    if (id) this.$emit("delete", id);
  }
}
