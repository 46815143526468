

























































import { Vue, Component, VModel, Watch } from "vue-property-decorator";
import ymaps, { Event as YMapEvent } from "yandex-maps";

type YandexGeocodeResponse = {
  GeoObjectCollection: {
    featureMember: Array<{
      GeoObject: {
        Point: { pos: string };
        description: string;
        name: string;
        metaDataProperty: {
          GeocoderMetaData: { text: string };
        };
      };
    }>;
  };
};

@Component
export default class Map extends Vue {
  @VModel({ type: String }) location!: string;

  public $refs!: {
    validator?: Vue & {
      validate: () => Promise<boolean>;
    };
  };

  protected reconfigureMaps(e: ymaps.Map): void {
    // Предотвращает установку второй метки на карту
    const searchControl = e.controls.get("searchControl");
    // Типы не описывают менеджер настроек но он есть
    // @ts-ignore
    searchControl?.options.set("noPlacemark", true);
  }

  private async pickCoordinate(e: YMapEvent): Promise<void> {
    const coords = e.get("coords") as Array<number>;
    this.location = `(${coords.join(",")})`;
  }

  private get parsedCoords(): Array<string> {
    const pos = this.location;
    if (!this.location) return ["57.234572461878486", "42.628587121340786"];
    return pos ? pos.replace("(", "").replace(")", "").split(",") : [];
  }

  public loading = false;
  public locationName: string | null = null;

  @Watch("location")
  protected async yandexUpdateLocationData(): Promise<void> {
    if (!this.location) return;
    await this.$nextTick();

    try {
      this.loading = true;
      const params = new URLSearchParams();
      params.set("apikey", process.env.VUE_APP_SECRET_MAPS_KEY as string);
      params.set("geocode", this.parsedCoords.slice().reverse().join(","));
      params.set("format", "json");
      params.set("results", "1");

      const response = await fetch(
        `https://geocode-maps.yandex.ru/1.x?${params.toString()}`
      );
      const data = await response.json();

      if (data) {
        const response: YandexGeocodeResponse = data.response;
        this.locationName =
          response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.text;
      }
    } catch (e) {
      console.error(
        `[ Yandex Maps ]: Адрес не распознан по коду "${this.parsedCoords}"`
      );
      this.locationName = null;
    } finally {
      // Яндекс карты работают быстро, эта задержка уменьшает мерцание индикаторов загрузки
      setTimeout(() => (this.loading = false), 300);
      await this.$nextTick();
      await this.$refs.validator?.validate();
    }
  }
}
