<template>
  <v-card>
    <v-card-title class="mb-4">Добавить партнера</v-card-title>
    <v-card-text class="mb-10">
      <v-row>
        <v-autocomplete
          v-model="checkedPartner"
          :items="partners.data"
          item-text="name"
          :loading="$apollo.queries.partners.loading"
          return-object
          item-value="id"
          label="Поиск партнеров"
          placeholder="начните вводить название"
          prepend-inner-icon="mdi-account-search"
          clearable
          hide-details
          hide-no-data
          @update:search-input="onSearchInput"
        >
        </v-autocomplete>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        color="primary"
        depressed
        class="mr-2"
        :disabled="!checkedPartner"
        @click="onClickAdd"
      >
        Добавить
      </v-btn>
      <v-btn text @click="$emit('cancel')"> Отмена </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { PartnersEventList } from "@/graphql/queries/Partners.graphql";
import {
  QueryPartnersOrderByColumn,
  QueryPartnersWhereColumn,
  SortOrder,
  SqlOperator,
} from "@/generated/graphql";
import { debounce } from "@/utils";
import { BASE_DEBOUNCE_DELAY } from "@/utils/constants";

export default {
  apollo: {
    partners: {
      query: PartnersEventList,
      variables() {
        return {
          first: 10,
          page: 1,
          orderBy: [
            { column: QueryPartnersOrderByColumn.Id, order: SortOrder.Desc },
          ],
        };
      },
    },
  },

  data() {
    return {
      partners: {
        data: [],
      },
      checkedPartner: null,
    };
  },

  methods: {
    onSearchInput: debounce(async function (input) {
      try {
        if (input) {
          await this.$apollo.queries.partners.refetch({
            where: {
              column: QueryPartnersWhereColumn.Name,
              operator: SqlOperator.Ilike,
              value: input ? `%${input.trim()}%` : "",
            },
          });
        } else {
          this.partners.data = [];
        }
      } catch (e) {
        console.error(e);
      }
    }, BASE_DEBOUNCE_DELAY),

    onClickAdd() {
      if (this.checkedPartner) {
        this.$emit("add", { ...this.checkedPartner });
      } else {
        this.$emit("cancel");
      }
    },
  },
};
</script>
