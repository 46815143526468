



















































import { Vue, Component, Emit, Prop, VModel } from "vue-property-decorator";
import PageCard from "@/components/page/Card.vue";

@Component({
  name: "Files",
  components: {
    PageCard,
  },
})
export default class Files extends Vue {
  @Prop(Boolean) dense!: boolean;
  @Prop(String) label!: string;
  @Prop({ type: String, default: "*" }) accept!: string;
  @Prop({ type: Boolean, default: false }) multiple!: boolean;
  @Prop({ type: Array, default: () => [] }) errorMessages!: string[];
  @VModel({ type: [Array, File, String] }) files!:
    | File
    | Array<File>
    | string
    | null;

  public $refs!: {
    input: HTMLInputElement;
  };

  private get selectedFiles(): Array<File | null> {
    if (typeof this.files === "string") return [];

    return this.files
      ? Array.isArray(this.files)
        ? this.files
        : [this.files]
      : [];
  }

  private get linkDownload(): string | null {
    if (typeof this.files === "string") return this.files;
    return null;
  }

  spliceFile(index: number): void {
    if (this.multiple) {
      const value = [...this.selectedFiles];
      value.splice(index, 1);
      this.files = value as Array<File>;
    } else {
      this.files = null;
    }
  }

  @Emit("input")
  protected selectFromFileSystem(e: Event): void {
    this.$refs.input.click();
  }

  protected onFileSelected(e: Event) {
    const target = e.target as HTMLInputElement;
    const files = target.files as FileList;
    this.files = this.multiple ? Array.from(files) : files[0];
  }
}
