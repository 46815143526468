















































































































































import { Component, Prop, PropSync, VModel, Vue } from "vue-property-decorator";
import { PersonStatus, User } from "@/generated/graphql";
import { DataTableHeader } from "vuetify";

import Card from "@components/page/Card.vue";
import UsersList from "@/components/widgets/user/UsersList.vue";
import PersonStatusAutocomplete from "@widgets/commons/Inputs/PersonStatusAutocomplete.vue";

export enum Person {
  Anchorperson = "anchorperson",
  Manager = "manager",
  Producer = "producer",
  Lecturer = "lecturer",
}

@Component({
  components: {
    Card,
    UsersList,
    PersonStatusAutocomplete,
  },
})
export default class EventPersons extends Vue {
  @VModel({ type: Array, default: () => [] }) items!: User[];
  @Prop({ type: String, required: true }) personType!: Person;
  @Prop({ type: Boolean, default: false }) disableHideCheckbox!: Person;
  @PropSync("hideBlock", { type: Boolean, default: false })
  hideBlockSync!: boolean;

  protected readonly headers: DataTableHeader[] = [
    { text: "Фото", value: "avatar", sortable: false },
    { text: "ФИО", value: "fullname", sortable: false },
    { text: "Статус", value: "status", width: 300, sortable: false },
    { text: "Регалии", value: "comment", sortable: false },
    { text: "", value: "actions", sortable: false },
  ];

  created() {
    if (!this.items) this.items = [];
  }

  protected revokeUser = null;
  protected usersVisible = false;
  protected selected: User[] = [];

  private openUserModal(): void {
    this.selected = [...this.items];
    this.usersVisible = true;
  }

  protected spliceUser(user: User): void {
    this.items.splice(this.items.indexOf(user), 1);
    this.revokeUser = null;
  }

  protected getPersonStatus(user: User): string | null {
    switch (this.personType) {
      case Person.Anchorperson:
        return user.anchor_status?.person_status_id || null;
      case Person.Lecturer:
        return user.lecturer_status?.person_status_id || null;
      case Person.Manager:
        return user.manager_status?.person_status_id || null;
      case Person.Producer:
        return user.producer_status?.person_status_id || null;
      default:
        return null;
    }
  }

  protected setPersonStatus(user: User, status: PersonStatus["id"]): void {
    switch (this.personType) {
      case Person.Anchorperson:
        !user.anchor_status?.person_status_id
          ? (user.anchor_status = {
              person_status_id: status,
            })
          : (user.anchor_status.person_status_id = status);
        break;
      case Person.Lecturer:
        !user.lecturer_status?.person_status_id
          ? (user.lecturer_status = { person_status_id: status })
          : (user.lecturer_status.person_status_id = status);
        break;
      case Person.Manager:
        !user.manager_status?.person_status_id
          ? (user.manager_status = { person_status_id: status })
          : (user.manager_status.person_status_id = status);
        break;
      case Person.Producer:
        !user.producer_status?.person_status_id
          ? (user.producer_status = { person_status_id: status })
          : (user.producer_status.person_status_id = status);
        break;
    }
  }
}
