















































import { Component, Prop, Vue } from "vue-property-decorator";
import DownloadButton from "@components/Download.vue";

@Component({
  components: {
    DownloadButton,
  },
})
export default class AdditionalBlockTextPreview extends Vue {
  @Prop({ type: String, default: null }) name!: string | null;
  @Prop({ type: String, default: null }) text!: string | null;
  @Prop({ type: [String, Object, File], default: null }) file!:
    | File
    | string
    | undefined;
}
