var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.partnerFormVisible),callback:function ($$v) {_vm.partnerFormVisible=$$v},expression:"partnerFormVisible"}},[_c('PartnerSearchCard',{on:{"add":_vm.onAddPartner,"cancel":function($event){_vm.partnerFormVisible = false}}})],1),_c('v-snackbar',{attrs:{"color":"green"},model:{value:(_vm.statusFormDone),callback:function ($$v) {_vm.statusFormDone=$$v},expression:"statusFormDone"}},[_vm._v("Статус партнера создан")]),_c('v-snackbar',{attrs:{"color":"red"},model:{value:(_vm.statusFormError),callback:function ($$v) {_vm.statusFormError=$$v},expression:"statusFormError"}},[_vm._v("Статус партнера не удалось сохранить")]),_c('v-snackbar',{attrs:{"color":"primary"},model:{value:(_vm.statusDeleteTipVisible),callback:function ($$v) {_vm.statusDeleteTipVisible=$$v},expression:"statusDeleteTipVisible"}},[_vm._v("Для удаления статуса партнера, кликните ДВАЖДЫ по кнопке удаления")]),_c('Table',{attrs:{"headers":_vm.headers,"items":_vm.partners,"show-select":_vm.showSelect,"disable-export":""},scopedSlots:_vm._u([{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"red"},on:{"click":function($event){return _vm.removePartner(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"outlined":"","text":"","small":"","color":"primary"},on:{"click":function($event){_vm.partnerFormVisible = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" добавить ")],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"value":item.pivot && item.pivot.partner_status_id,"error-messages":errors,"items":_vm.partnerStatuses,"search-input":_vm.search,"item-value":"id","item-text":"status","label":"Статус партнера","chips":"","deletable-chips":""},on:{"change":function($event){_vm.addPartner(item, $event);
              _vm.search = '';},"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-n2 py-4",attrs:{"text":"","color":"primary","block":""}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1),_c('v-divider')]}}],null,true),model:{value:(_vm.statusFormVisible),callback:function ($$v) {_vm.statusFormVisible=$$v},expression:"statusFormVisible"}},[_c('v-card',[_c('v-card-title',[_vm._v("Новый статус")]),_c('v-card-text',[_vm._v(" Статус будет сохранен для будущего использования ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var valid = ref.valid;
return [_c('v-card-text',[_c('ValidationProvider',{attrs:{"rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"название статуса партнера","error-messages":errors},model:{value:(_vm.statusFormValue),callback:function ($$v) {_vm.statusFormValue=$$v},expression:"statusFormValue"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!valid || _vm.statusFormProgress,"loading":_vm.statusFormProgress},on:{"click":_vm.addStatus}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.statusFormVisible = false}}},[_vm._v(" Отменить ")])],1)]}}],null,true)})],1)],1)]},proxy:true},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.status)+" "),_c('div',[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","loading":_vm.statusDeleteProgress === item.id,"small":"","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.statusDeleteTipVisible = true},"dblclick":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteStatus(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" ДВОЙНОЙ клик для удаления ")])],1)])]}}],null,true)})]}}],null,true)})],1)]}},{key:"item.logotype",fn:function(ref){
              var item = ref.item;
return [_c('v-img',{staticClass:"grey lighten-4 rounded my-2",attrs:{"height":"100px","aspect-ratio":1 / 1,"contain":"","src":item.logotype},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"d-flex fill-height justify-center align-center"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-image-area")])],1)]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }