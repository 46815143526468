































































import { Component, PropSync, Vue } from "vue-property-decorator";
import ImageInput from "@widgets/commons/Inputs/ImageInput.vue";

@Component({ components: { ImageInput } })
export default class AdditionalBlockImage extends Vue {
  @PropSync("name", { type: String, default: null }) nameSync!: string | null;
  @PropSync("text", { type: String, default: null }) textSync!: string | null;
  @PropSync("file", { type: [Object, String, File], default: null }) fileSync!:
    | File
    | string
    | null;
  @PropSync("image", { type: [Object, String, File, Array], default: null })
  imageSync!: File | File[] | string | null;

  protected get isFile(): boolean {
    return this.fileSync instanceof File;
  }

  protected get filename(): string {
    const name =
      this.fileSync && this.fileSync instanceof File
        ? this.fileSync.name
        : this.fileSync || "";

    return name.split("/").pop()?.substr(0, 100) || "";
  }
}
